import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { passwordSchema } from "../schema";
import { passwordTextFieldClasses } from "../../../styles/mui/passwordTextField";
import { I18n } from "react-redux-i18n";
import * as styles from ".././authentication.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  commonFormBase,
  muiStylesAuthentication,
} from "../muiStylesAuthentication";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import clsx from "clsx";
import { handleNavigateToLogin } from "../../../utils/paths";
import {
  changeUserPasswordExpired,
} from "../../../redux/actions/userActions";
import {
  selectUserChangePasswordExpiredSuccess,
  selectUserChangePasswordLoading,
} from "../../../redux/selectors/userSelectors";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import ValidationState from "../../Forms/ValidationState";
import { useIsCapsLockOn } from "../../../utils/hooks";

interface Props {
  userId?: string | unknown;
}

const ExpiredPasswordComponent: FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();

  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowPassword] = useState(false);
  const capsLock = useIsCapsLockOn();
  const requestPending = useSelector(selectUserChangePasswordLoading);
  const requestSucceeded = useSelector(selectUserChangePasswordExpiredSuccess);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<{ currPassword: string; newPassword: string }>({
    mode: "all",
    defaultValues: {
      currPassword: "",
      newPassword: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(passwordSchema),
    criteriaMode: "all",
  });

  const onSubmit = handleSubmit((data): void => {
    if (userId) {
      const dataToSend = {
        userId,
        currPassword: data.currPassword,
        newPassword: data.newPassword,
      };

      dispatch(changeUserPasswordExpired(dataToSend));
    }
  });

  useEffect(() => {
    setError("newPassword", { types: { min: "min", matches: "matches" } });
  }, [setError]);

  return (
    <>
      {requestSucceeded ? (
        <div className={clsx(styles.loginForm, styles.loginFormSuccess)}>
          <h1 className={styles.header}>
            {I18n.t("PasswordExpired.success.header")}
          </h1>
          <div className={styles.formSuccessMessageContainer}>
            {I18n.t("PasswordExpired.success.text")}
          </div>
          <PrimaryButton
            text={I18n.t("Buttons.goToDashboard")}
            event={handleNavigateToLogin}
          />
        </div>
      ) : (
        <>
          <form onSubmit={onSubmit} className={styles.loginForm}>
            <h1 className={styles.header}>
              {I18n.t("PasswordExpired.form.header")}
            </h1>
            <div className={styles.formMessageContainer}>
              {I18n.t("PasswordExpired.form.text")}
            </div>
            <div className={styles.gap}>
              <Controller
                name="currPassword"
                render={({ field }) => (
                  <TextField
                    {...register("currPassword")}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    label={I18n.t("FormLabels.currentPassword")}
                    sx={muiStylesAuthentication.inputResetPassword}
                    type={showCurrPassword ? "text" : "password"}
                    disabled={requestPending}
                    InputProps={{
                      sx: muiStylesAuthentication.inputResetPassword,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            sx={commonFormBase.passwordVisibility}
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowCurrPassword(!showCurrPassword)
                            }
                          >
                            {showCurrPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
                control={control}
              />
            </div>
            <Controller
              name="newPassword"
              render={({ field }) => (
                <TextField
                  {...register("newPassword")}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={I18n.t("FormLabels.password")}
                  sx={muiStylesAuthentication.inputResetPassword}
                  type={showNewPassword ? "text" : "password"}
                  disabled={requestPending || requestSucceeded}
                  InputProps={{
                    sx: muiStylesAuthentication.inputResetPassword,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={commonFormBase.passwordVisibility}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              control={control}
            />

            <div
              className={clsx(
                styles.loginMessagesContainer,
                styles.resetPassword
              )}
            >
              {capsLock && (
                <div className={styles.capslockContainer}>
                  <ErrorOutlineOutlinedIcon fontSize="small" />
                  <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
                </div>
              )}
              <p className={styles.validatorContainer}>
                <ValidationState
                  error={"min" in (errors?.newPassword?.types || {})}
                />
                <span className={styles.validatorText}>
                  {I18n.t("FormValidation.Password.length")}
                </span>
              </p>
              <p className={styles.validatorContainer}>
                <ValidationState
                  error={"matches" in (errors?.newPassword?.types || {})}
                />
                <span className={styles.validatorText}>
                  {I18n.t("FormValidation.Password.characters")}
                </span>
              </p>
            </div>

            <div className={styles.formButtonsContainer}>
              <PrimaryButton
                text={I18n.t("Buttons.savePassword")}
                disabled={!isValid || requestPending || requestSucceeded}
                isSubmit
              />
              {requestPending && (
                <CircularProgress
                  sx={passwordTextFieldClasses.base}
                  size={24}
                />
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ExpiredPasswordComponent;
